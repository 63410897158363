import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import map1 from "../assets/img/map1.jpg";
import map2 from "../assets/img/map2.jpg";
import {Hidden} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    title: {
        marginTop: theme.spacing(4),
        textAlign: "center"
    }
}));


function Maps() {
    const classes = useStyles();

    return (
        <Fragment>

            <Grid container justify="center" alignItems="center" className={classes.view}>
                <Grid item xs={12}>
                    <h3 className={classes.title}>Our Location</h3>
                </Grid>
                <Grid container xs={12} justify="center" alignItems="center">
                    <p>Please click <a target="_blank"
                                       href="https://www.google.com/maps/place/25%C2%B055'49.0%22S+28%C2%B023'46.5%22E/@-25.930288,28.3940493,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d-25.9302881!4d28.3962379">here </a>
                        to view our location on Google Maps.</p>
                </Grid>
            </Grid>


            <Grid container justify="center" alignItems="center" className={classes.view}>
                <Grid container justify="center" alignItems="center" xs={12}>
                    <h3 className={classes.title}>From the entrance</h3>
                </Grid>

                <Grid container justify="center" alignItems="center" xs={12}>
                    <Hidden mdUp>
                        <img src={map1} alt={"Fishing spot location"} style={{width: '100%'}}/>
                    </Hidden>
                    <Hidden smDown>
                        <img src={map1} alt={"Fishing spot location"}/>
                    </Hidden>
                </Grid>

                <Grid container justify="center" alignItems="center" xs={12}>
                    <h3 className={classes.title}>Fishing spots layout</h3>
                </Grid>
                <Grid container justify="center" alignItems="center" xs={12} style={{marginBottom: '150px'}}>
                    <Hidden mdUp>
                        <img src={map2} alt={"Fishing spot location"} style={{width: '100%'}}/>
                    </Hidden>
                    <Hidden smDown>
                        <img src={map2} alt={"Fishing spot location"}/>
                    </Hidden>
                </Grid>

            </Grid>


        </Fragment>

    );
}

export default (Maps);
