import React, {Fragment, useEffect, useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import BootstrapInput from "../components/BootstrapInput";
import ChildDetails from "../components/ChildDetails";
import {green} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import {useStateValue} from "../state/StateProvider";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import withWidth from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    margin: {
        marginTop: theme.spacing(3),
    },
    padding: {
        paddingRight: theme.spacing(1),
    },
    margin1: {
        marginTop: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '0',
        fontSize: '12px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1',
        letterSpacing: '0.00938em',
    },
    noTransform: {
        transform: 'none'
    },
    title: {
        textAlign: "center",
        color: '#555555',
        fontWeight: '300',
    },
}));

export const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);


function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function CustomerDetails({width, history}) {
    const classes = useStyles();
    const [{formValid}, dispatch] = useStateValue();
    const [tnc, setTnc] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [firstNameValid, setFirstNameValid] = useState(false);
    const [lastNameValid, setLastNameValid] = useState(false);
    const [numberValid, setNumberValid] = useState(false);

    useEffect(() => {
        // validate the form
        if (emailValid && firstNameValid && lastNameValid && numberValid && tnc) {
            if (!formValid) {
                dispatch({type: 'FORM_VALID', data: true});
            }
        }
    }, [emailValid, firstNameValid, formValid, lastNameValid, numberValid, tnc]);

    const emailProps = !emailValid ? {error: true} : null;
    const firstNameProps = !firstNameValid ? {error: true} : null;
    const lastNameProps = !lastNameValid ? {error: true} : null;
    const numberProps = !numberValid ? {error: true} : null;

    return (
        <Fragment>
            <Container maxWidth="sm">
                <h3 className={classes.title}>
                    <strong>Customer Details</strong>
                </h3>

                <p className={classes.title}>
                    Please enter your details below
                </p>

                <form className={classes.form} noValidate>
                    <FormControl className={classes.margin1} fullWidth required {...emailProps}>
                        <InputLabel shrink htmlFor="Email">
                            Email
                        </InputLabel>
                        <BootstrapInput
                            defaultValue=""
                            id="Email"
                            onChange={(e) => {
                                dispatch({type: 'O_CUSTOMER', data: {email: e.target.value}});
                                setEmail(e.target.value);
                                // setEmailValid(validateEmail(e.target.value));
                            }}
                            inputProps={{
                                onBlur: (e) => {
                                    setEmailValid(validateEmail(e.target.value))
                                }
                            }}
                        />
                    </FormControl>

                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <FormControl required
                                         className={clsx([classes.margin, width !== 'xs' ? classes.padding : ""])}
                                         fullWidth {...firstNameProps}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    First Name
                                </InputLabel>
                                <BootstrapInput defaultValue="" id="bootstrap-input"
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                    dispatch({type: 'O_CUSTOMER', data: {firstName: e.target.value}});
                                                    // setNameValid(e.target.value !== '');
                                                }}
                                                inputProps={{
                                                    onBlur: (e) => {
                                                        setFirstNameValid(e.target.value !== '');
                                                    }
                                                }}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl required className={classes.margin} fullWidth {...lastNameProps}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    Last Name
                                </InputLabel>
                                <BootstrapInput defaultValue="" id="bootstrap-input"
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                    dispatch({type: 'O_CUSTOMER', data: {lastName: e.target.value}});
                                                    // setNameValid(e.target.value !== '');
                                                }}
                                                inputProps={{
                                                    onBlur: (e) => {
                                                        setLastNameValid(e.target.value !== '');
                                                    }
                                                }}/>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <FormControl className={classes.margin} fullWidth {...numberProps}>
                        <InputLabel shrink htmlFor="phone">
                            Phone Number
                        </InputLabel>
                        <BootstrapInput defaultValue="" id="phone"
                                        onChange={(e) => {
                                            setNumber(e.target.value);
                                            dispatch({type: 'O_CUSTOMER', data: {phoneNumber: e.target.value}});
                                            // setNumberValid(e.target.value !== '');
                                        }}
                                        inputProps={{
                                            onBlur: (e) => {
                                                const valid = e.target.value !== '';
                                                setNumberValid(valid);
                                                if (!valid) dispatch({type: 'FORM_VALID', data: false});
                                            }
                                        }}/>
                    </FormControl>

                    <FormControl className={classes.margin} fullWidth {...numberProps}>
                        <InputLabel shrink htmlFor="phone">
                            Vehicle Registration Numbers
                        </InputLabel>
                        <BootstrapInput
                            multiline
                            rows={4}
                            defaultValue=""
                            id="phone"

                            onChange={(e) => {
                                setNumber(e.target.value);
                                dispatch({type: 'O_CUSTOMER', data: {phoneNumber: e.target.value}});
                                // setNumberValid(e.target.value !== '');
                            }}

                            inputProps={{
                                onBlur: (e) => {
                                    const valid = e.target.value !== '';
                                    setNumberValid(valid);
                                    if (!valid) dispatch({type: 'FORM_VALID', data: false});
                                }
                            }}/>
                    </FormControl>

                    <FormControlLabel
                        className={classes.margin}
                        control={
                            <GreenCheckbox
                                checked={tnc}
                                onChange={() => setTnc(!tnc)}
                                value="checkedG"
                            />
                        }
                        label="I have read the terms and conditions"
                    />

                    <Grid container justify="center" className={classes.topSpacing}>


                        <Button variant="contained"
                                disabled={!formValid}
                                color="primary"
                                onClick={() => {
                                    history.push('/checkout');
                                }}
                                className={classes.button}>Continue to checkout</Button>
                    </Grid>
                </form>
            </Container>
        </Fragment>
    );
}

export default withWidth()(withRouter(CustomerDetails));
