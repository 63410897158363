import * as config from "../aws-exports";
import moment from "moment";
import {AwsClient} from 'aws4fetch'


function endpoint(s) {
    const host = config.apihost;
    return `https://${host}/${s}`;
}


export async function getCompany(dispatch) {


    try {
        dispatch({
            type: 'GET_COMPANY',
        });

        const response = await fetch(endpoint(`v1/companies`), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': config.apikey,
            },
            cache: 'no-cache',
            method: 'GET',
        });

        const ret = await response.json();

        dispatch({
            type: 'GET_COMPANY_COMPLETED',
            data: ret
        });


    } catch (error) {
        dispatch({
            type: 'GET_COMPANY_COMPLETED',
            error: error
        });
    }


}


export async function getLocations(dispatch) {

    try {
        dispatch({
            type: 'GET_LOCATIONS'
        });

        const req = await fetch(endpoint(`bookings/locations`), {
            cache: 'no-cache',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': config.apikey,
            },
        });

        const ret = await req.json();

        dispatch({
            type: 'GET_LOCATIONS_COMPLETED',
            data: ret
        });


    } catch (error) {
        dispatch({
            type: 'GET_LOCATIONS_COMPLETED',
            error: error
        });
    }


}


export async function getLocationEvents(dispatch, locationId) {

}

export async function getLocationResources(dispatch, locationId) {


    dispatch({type: 'FETCH_RESOURCES'});

    try {
        const events = await fetch(`${endpoint('resources')}?locationId=${locationId}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': config.apikey,
            },
        });

        const rsp = await events.json();

        dispatch({
            type: 'FETCH_RESOURCES_COMPLETED',
            data: rsp.sort((a, b) => a.name > b.name),
        });


    } catch (error) {
        dispatch({
            type: 'FETCH_RESOURCES_COMPLETED',
            error: error
        });
    }


}

export async function getAvailableResources(dispatch, locationId, startTime, endTime) {


    dispatch({type: 'FETCH_RESOURCES'});

    try {
        const events = await fetch(`${endpoint('available-resources')}?location=${locationId}&startTime=${startTime}&endTime=${endTime}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': config.apikey,
            },
        });

        const rsp = await events.json();

        dispatch({
            type: 'FETCH_RESOURCES_COMPLETED',
            data: rsp,
        });

        return true;
    } catch (error) {
        dispatch({
            type: 'FETCH_RESOURCES_COMPLETED',
            error: error
        });
        return error;
    }


}

export async function getLocationServices(dispatch, locationId) {

    const events = await fetch(`${endpoint('appointments/services')}?locationId=${locationId}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': config.apikey,
        },
    });

    return events.json();
}


export async function getCustomerProfile(token) {

    const response = await fetch(endpoint(`bookings/customer-profile`), {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
            'x-api-key': config.apikey,
        },
        cache: 'no-cache',
        method: 'GET',
    });

    return response.json();

}


export function registerPaymentResult(params) {
    return async (dispatch) => {

        dispatch({
            type: 'PAYMENT_RESULT',
            data: params
        });

    }
}

export async function getFederatedCustomerProfile(auth) {

    try {
        const aws = new AwsClient({
            service: 'execute-api',
            region: 'eu-west-1',
            accessKeyId: auth.accessKeyId,
            secretAccessKey: auth.secretAccessKey,
            sessionToken: auth.sessionToken,
        });


        const res = await aws.fetch(endpoint(`bookings/fed-customer-profile`), {});

        return res.json();
    } catch (e) {
        console.log(e)
    }
}


// export interface CreateBookingRequest {
//     locationId: string;
//     basket?: BasketItem[];
//     basketTotal?: number;
//     discounted?: boolean;
//     discountAmount?: number;
//     originatingCustomer: BookingCustomer;
//     paymentType: string;
//     paymentAmount: number;
//     reference?: string;
//     currency?: string;
//     fields?: any[];
//     attributes?: any[];
// }


// export interface BasketItem {
//     event: string;
//     customer: BookingCustomer;
//     total: number;
// }
export async function initTransaction({company, dispatch, location, basketTotal, rawBasket, basket, customer, paymentType, currency, user, reference}) {

    // if(basket.filter(it=>it.))

    const basketItems = rawBasket;
    let originatingCustomer;

    originatingCustomer = {
        phoneNumber: customer.phoneNumber,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
    };


    const ref = reference || `${originatingCustomer.firstName}-${Math.random().toString(36).slice(2, 5).toUpperCase().replace('O', 'A').replace('0', 'Z')}`;

    const body = {
        company,
        locationId: location,
        basket: basketItems,
        originatingCustomer: originatingCustomer,
        paymentType: paymentType,
        currency: currency || 'ZAR',
        paymentAmount: basketTotal,
        reference: ref,
        attributes: []
    };

    const response = await fetch(endpoint(`bookings/init`), {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': config.apikey,
        },
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify(body)
    });

    const data = await response.json();

    console.log(data);

    dispatch({
        type: `${paymentType.toUpperCase()}_INIT_COMPLETE`,
        data: data
    });

}

export function initPayGate(state) {
    return async (dispatch) => {

        const {selectedBranch, basketItems, basketTotal} = state;
        const events = basketItems.map(it => it.EventId);
        const {parentName, phone, children, parentEmail} = state.registrationDetails;
        const reference = `${state.registrationDetails.parentName.split(' ')[0]}-${Math.random().toString(36).slice(2, 5).toUpperCase().replace('O', 'A').replace('0', 'Z')}`;

        const childList = children.map(ch => {
            return {
                Name: ch.name,
                Birthday: ch.birthday,
                Schoolname: ch.schoolname,
            }
        });

        const body = {
            LocationId: selectedBranch,
            Events: events,
            OriginatingCustomer: {
                PhoneNumber: phone,
                Name: parentName,
                Email: parentEmail
            },
            BookingCustomers: childList,
            PaymentType: "PAYGATE",
            Currency: 'ZAR',
            PaymentAmount: basketTotal,
            Reference: reference,
            Attributes: [{
                Name: "BookingType",
                Value: "Term"
            }]
        };

        const response = await fetch(endpoint(`bookings/init`), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': config.apikey,
            },
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify(body)
        });

        const data = await response.json();

        console.log(data);

        dispatch({
            type: 'PAYGATE_INIT',
            data: data
        });
    }
}

export function pollSnapScan(id) {
    return async (dispatch) => {

        const response = await fetch(endpoint(`bookings/payment?id=${id}`), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': config.apikey,
            },
            cache: 'no-cache',
            method: 'GET'
        });

        const data = await response.json();

        console.log(data);

        dispatch({
            type: 'SNAPSCAN_POLL',
            data: data
        });
    }
}


export function startOver() {
    return async (dispatch) => {

        dispatch({
            type: 'START_OVER',
            data: []
        });

    }
}

export function addFormValue(formItem) {
    return async (dispatch) => {

        dispatch({
            type: 'ADD_FORM_VALUE',
            data: formItem
        });

    }
}

export function isMobile() {
    return async (dispatch) => {

        dispatch({
            type: 'IS_MOBILE',
            data: true
        });

    }
}


export function branchSelected(branch) {
    return async (dispatch) => {

        dispatch({
            type: 'BRANCH_SELECTED',
            data: branch
        });

    }
}

export function dispatch(data) {
    return async (dispatch) => {
        dispatch(data);
    }
}
