import React, {Fragment, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from "@material-ui/core/Grid";
import {useStateValue} from "../state/StateProvider";
import {getCompany, getLocations} from "../actions/appActions";
import AppBar from "@material-ui/core/AppBar";
import AddShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from "@material-ui/core/IconButton";
import {Container, withStyles, withWidth} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import BasketView from "../components/BasketView";
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state';
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import {Link, withRouter} from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PassIcon from '@material-ui/icons/BeachAccess';
import LocationIcon from '@material-ui/icons/LocationOn';
import gmapsIcon from '../assets/img/google-maps.svg';

import logo from "../assets/img/alwyn-logo.png";

const drawerWidth = 350;
const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: '300',
        },
    },

    layout: {
        marginTop: '40px',
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    content: {
        marginTop: '50px',
        [theme.breakpoints.up("md")]: {
            marginLeft: 350,
        },
        flexGrow: 1,
        padding: theme.spacing(3),
        minHeight: '100%',
    },
    contentMobile: {
        marginTop: '50px',
        flexGrow: 1,
        padding: theme.spacing(1),
        minHeight: '100%',
    },
    speedDial: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(3),
    },
    cart: {
        position: 'fixed',
        top: '5px',
        right: '10px',
        zIndex: '1200'
    },
    signout: {
        position: 'fixed',
        top: '5px',
        right: '80px',
        zIndex: '1200'
    },
    home: {
        position: 'fixed',
        top: '5px',
        right: '50px',
        zIndex: '1200'
    },
    clearCartIcon: {
        position: 'fixed',
        top: '5px',
        right: '140px',
        zIndex: '1200'
    },
    header: {
        backgroundColor: '#fff',
    },
    basket: {
        maxHeight: '70%',
        padding: theme.spacing(2),
        left: theme.spacing(2),
        width: '100%'
    },
    infoBar: {
        top: '50px',
        left: 0,
        color: '#555555',
        width: '320px',
        height: '100%',
        display: 'block',
        padding: '20px 40px',
        position: 'absolute',
        background: '#ffffff',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'white',
        elevation: 0,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: "#262262",
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        overflow: "auto",
        height: "100%",
        // marginTop: '66px',
        // backgroundColor: '#262262'
    },
    mobileDrawerPaper: {
        width: 280,
        overflow: "auto",
        height: "100%",
    },
    imgFluid: {
        maxWidth: "100%",
        height: "auto"
    },
    imgRounded: {
        borderRadius: "6px !important"
    },
    imgRoundedCircle: {
        borderRadius: "50% !important"
    },
    title: {
        display: "inline-block",
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        color: '#555555',
        fontWeight: '300',
    },
    versionGrid: {
        marginTop: theme.spacing(2),
    },
    version: {
        fontSize: '10px',
        color: '#999999',
        fontWeight: '200',
        bottom: 0,
        left: 0,
        textAlign: "center",
    },
    contact: {
        fontWeight: 300,
        color: "#6d6d6d",
        textAlign: "center !important",
    },
    description: {
        padding: '16px',
        fontWeight: 300,
        color: "#6d6d6d",
        textAlign: "center !important",
        marginBottom: theme.spacing(1)
    },
    social: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    toolbar: theme.mixins.toolbar,
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 1300,
    },
    name: {
        textAlign: 'center',
    },
    mobileImage: {
        width: '90px',
        height: 'auto',
    },
    menuImage: {
        marginTop: theme.spacing(2),

    },
    menuLogo: {
        width: '120px',
        height: 'auto',
    },
    avatar: {
        color: '#fff',
        backgroundColor: '#262262',
    },
}));

const StyledBadge = withStyles(theme => ({
    badge: {
        top: '10%',
        right: -3,
        // The border color match the background color.
        border: `1px solid ${
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
        }`,
    },
}))(Badge);

const StyledBadge2 = withStyles(theme => ({
    badge: {
        backgroundColor: '#f6c13c',
        color: '#262262',
        border: `1px solid ${
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
        }`,
    },
}))(Badge);

function AppContainer({children, width, step, history}) {
    const [{company, basket, basketTotal, userVouchers}, dispatch] = useStateValue();
    const [value, setValue] = React.useState(step);
    const [resourceFetchStarted, setResourceFetchStarted] = React.useState(false);

    const classes = useStyles();
    const imageClasses = `${classes.imgRoundedCircle} ${classes.imgFluid}`;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const isMobile = width === 'xs' || width === 'sm' || width === 'md';


    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen);
    }

    useEffect(() => {
        if (company && !resourceFetchStarted) {
            setResourceFetchStarted(true);
            // getLocationResources(dispatch)
        }
    }, [company]);


    const mobileDrawer = (
        <div
            className={classes.list}
            role="presentation"
            // onClick={toggleDrawer(side, false)}
            // onKeyDown={toggleDrawer(side, false)}
        >
            <div className={classes.toolbar} style={{textAlign: "center"}}>
                <img src={logo} alt="..." className={classes.menuLogo}/>
            </div>
            <Divider/>
            <List>


                <ListItem button onClick={() => {
                    dispatch({
                        type: 'OPTION',
                        data: null,
                    });
                    history.push("/")
                }}>
                    <ListItemIcon><HomeIcon/></ListItemIcon>
                    <ListItemText primary={"Book a spot"}/>
                </ListItem>


                <ListItem button onClick={() => history.push("/maps")}>
                    <ListItemIcon><LocationIcon/></ListItemIcon>
                    <ListItemText primary={"Location & Layout"}/>
                </ListItem>

                <ListItem button onClick={() => history.push("/terms-and-conditions")}>
                    <ListItemIcon><PassIcon/></ListItemIcon>
                    <ListItemText primary={"Terms and Conditions"}/>
                </ListItem>

                {
                    basket.length > 0 &&

                    <ListItem button onClick={() => history.push("/customer-details")}>
                        <ListItemIcon>
                            <StyledBadge badgeContent={basket.length} color="primary">
                                <AddShoppingCartIcon/>
                            </StyledBadge>
                        </ListItemIcon>
                        <ListItemText primary={"Checkout"}/>
                    </ListItem>

                }


            </List>
            <Divider/>
            <Fragment>

                <Grid container alignItems="center" alignContent="center" className={classes.menuImage}>
                    <Grid item xs={12} sm={12} alignItems="center" alignContent="center">
                        <div className={classes.profile}>
                            <div className={classes.name}>
                                <Button justIcon link className={classes.margin5}>
                                    <a target="_blank"
                                       href="https://www.google.com/maps/place/25%C2%B055'49.0%22S+28%C2%B023'46.5%22E/@-25.930288,28.3940493,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d-25.9302881!4d28.3962379">
                                        <img src={gmapsIcon} alt={"123"} style={{width: '35px'}}/></a>
                                </Button>
                            </div>

                        </div>
                    </Grid>
                </Grid>
                <div className={classes.description}>
                    <p>info@alwynfishing.co.za</p>
                    <p>Francois Viljoen 0723471737</p>
                    <strong>Bookings and Admin</strong>
                    <p>Denise Viljoen 0827772769</p>
                </div>

                <Grid container justify="center" className={classes.versionGrid}>

                    <p className={classes.version}>
                        {process.env.VERSION}{' '}
                        {'Copyright © '}
                        <a target="_blank" href="https://www.betterbook.co.za/">
                            BetterBook
                        </a>{' '}
                        {new Date().getFullYear()}
                    </p>
                </Grid>
            </Fragment>
        </div>
    );


    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {popupState => (
                <React.Fragment>
                    <CssBaseline/>
                    <AppBar elevation={1} position="fixed" className={classes.header}>

                        <Toolbar>
                            <Hidden lgUp>

                                <IconButton edge="start" className={classes.menuButton} color="inherit"
                                            aria-label="menu"
                                            onClick={() => handleDrawerToggle()}>
                                    <StyledBadge2 badgeContent={userVouchers.length} color="primary">
                                        <MenuIcon/>
                                    </StyledBadge2>
                                </IconButton>


                                <div style={{
                                    textAlign: "center",
                                    width: "78%",
                                }}>
                                    <img src={logo} alt="..." className={classes.mobileImage}/>
                                </div>
                            </Hidden>

                        </Toolbar>

                    </AppBar>

                    {/*Mobile Drawer*/}
                    <Hidden mdUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor={'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.mobileDrawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {mobileDrawer}
                        </Drawer>
                    </Hidden>


                    {/*Desktop Drawer*/}
                    <Hidden mdDown>
                        <Drawer
                            variant="permanent"
                            anchor={'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {mobileDrawer}
                        </Drawer>
                    </Hidden>


                    <Tooltip title="View cart details" placement="bottom">
                        <IconButton {...bindTrigger(popupState)}
                                    color="primary"
                                    className={classes.cart} aria-label="add to shopping cart">
                            <StyledBadge badgeContent={basket.length} color="primary">
                                <AddShoppingCartIcon/>
                            </StyledBadge>
                        </IconButton>
                    </Tooltip>

                    {/*Desktop Drawer*/}
                    <Hidden mdDown>
                        <a href="/">
                            <Tooltip title="Return to the home screen" placement="bottom">
                                <IconButton
                                    color="primary"
                                    className={classes.home}
                                    onClick={() => {
                                        dispatch({
                                            type: 'OPTION',
                                            data: null,
                                        });
                                        history.push('/')
                                    }}
                                >
                                    <Link to="/"> <HomeIcon/></Link>

                                </IconButton>


                            </Tooltip>
                        </a>
                    </Hidden>

                    <Popover
                        {...bindPopover(popupState)}
                        className={classes.basket}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <BasketView currencySymbol={"R"} basketTotal={basketTotal} basket={basket} onClose={() => {
                        }}/>
                    </Popover>

                    {
                        isMobile ? (
                            <main className={classes.contentMobile}>
                                {children}
                            </main>
                        ) : (
                            <main className={classes.content}>
                                <Container maxWidth="md">
                                    {children}
                                </Container>
                            </main>
                        )
                    }


                </React.Fragment>
            )}
        </PopupState>
    );
}


export default withRouter(withWidth()(AppContainer));
