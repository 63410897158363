// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
export const apihost = "api.betterbook.co.za/oprod";
export const env = 'prod';
export const version = '1.0.2';
export const apikey = 'cfdda0d6a2190893baea946f18127aaa994adac14c440ac68c16bec73a47e2f43edb7c3330ba145d4ad9d63f2a2b00e6769c7b018bc01823cd';


const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:44df65e3-0d5b-451e-a9da-55a1539f5246",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_NtxIjOSCh",
    "aws_user_pools_web_client_id": "33cqc95uie4msda0g0ngde3tvu",
    "oauth": {
        "domain": "betterbook3794d12d-3794d12d-prod.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": 'https://dynamex031.co.za/',
        "redirectSignOut": 'https://dynamex031.co.za/',
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://owcouyx37zd4bj4dvdqefiiive.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "betterbookbd5ce7229f844c32936b9b79c9c864ae-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "b5f9f6c912f7422cb159e0d663de04f6",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
