import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import AuthedApp from "./AuthedApp";
import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn:  "https://cc5e2428c18f46239b0b5bbe49deb6be@o256749.ingest.sentry.io/5243493",
    release: "bb-accommodation-mui@" + process.env.VERSION,
    integrations: [new Sentry.Integrations.GlobalHandlers({
        onunhandledrejection: true,
    })],
    beforeSend: (event, hint) => {
        event.error && console.log(event.error.errorMessage);

        if (hint && hint.originalException instanceof Event) {
            event.extra.isTrusted = hint.originalException.isTrusted;
        }

        return event;
    },
});

Sentry.configureScope((scope) => {
    scope.setTag("version", process.env.VERSION);
});

ReactDOM.render(<AuthedApp/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
