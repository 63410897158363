import React, {Fragment, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Typography, withWidth} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {useStateValue} from "../state/StateProvider";
import clsx from "clsx";
import DateRangePickerWrapper from "../components/DateRangePickerWrapper";
import {getAvailableResources} from "../actions/appActions";
import MultiSearchSelect from "../components/MultiSearchSelect";
import {GreenCheckbox} from "../components/CustomerDetails";
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css';
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import BootstrapInput from "../components/BootstrapInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";
import moment from "moment";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import {amber, green,} from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const useStyles = makeStyles(theme => ({
    section:{
        marginTop: theme.spacing(6)
    },
    title: {
        textAlign: "center",
        color: '#555555',
        fontWeight: '300',

    },
    mTop2: {
        marginTop: theme.spacing(2),
    },
    mTop: {
        marginTop: theme.spacing(4),
    },
    headcountPadding: {
        marginLeft: '4px',
    },
    headcount: {
        marginTop: '8px',
    },
    margin: {
        marginTop: theme.spacing(3),
    },
    padding: {
        paddingRight: theme.spacing(1),
    },
    margin1: {
        marginTop: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
}));

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: '#82d0f7',
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const {className, message, onClose, variant, ...other} = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            style={{
                marginTop: '-10px',
                marginBottom: '16px',
            }}
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)}/>
                    {message}
        </span>
            }

            {...other}
        />
    );
}

function OptionsView({width, history, onSelect}) {
    const classes = useStyles();
    const [{resources, location, loading, formValid}, dispatch] = useStateValue();
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [resourceLoading, setResourceLoading] = useState(null);
    const [resourceSelect, setResourceSelect] = useState([]);
    const [resourceSelectValue, setResourceSelectValue] = useState(null);
    const [tnc, setTnc] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [vehicles, setVehicles] = useState('');
    const [number, setNumber] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [firstNameValid, setFirstNameValid] = useState(false);
    const [lastNameValid, setLastNameValid] = useState(false);
    const [numberValid, setNumberValid] = useState(false);
    const [vehicleValid, setVehicleValid] = useState(false);
    const [estimate, setEstimate] = useState(null);
    const [numDays, setNumDays] = useState(null);
    const [headcount, setHeadcount] = useState(1);
    const [price, setPrice] = useState(400);


    const emailProps = !emailValid ? {error: true} : null;
    const firstNameProps = !firstNameValid ? {error: true} : null;
    const lastNameProps = !lastNameValid ? {error: true} : null;
    const numberProps = !numberValid ? {error: true} : null;

    useEffect(() => {
        // validate the form
        if (emailValid && firstNameValid && lastNameValid && numberValid && tnc) {
            if (!formValid) {
                dispatch({type: 'FORM_VALID', data: true});
            }
        }
    }, [emailValid, firstNameValid, formValid, lastNameValid, numberValid, tnc]);


    useEffect(() => {
        if (startTime && endTime) {
            setResourceLoading(true);
            getAvailableResources(dispatch, location.BranchId, startTime.toISOString(), endTime.toISOString())
                .then(() => setResourceLoading(null));
            const dayDiff = moment(endTime).diff(startTime, 'days');
            const days = dayDiff + 1;
            setNumDays(days);


            setPrice(400);
            setEstimate(days * 400);


        }
    }, [startTime, endTime, headcount]);

    useEffect(() => {
        if (resources && resources.length > 0) {
            setResourceSelect(
                resources
                    .sort((a, b) => a.name.length - b.name.length)
                    .map(res => ({
                        label: res.name,
                        value: res.id,
                    })));
        }
    }, [resources]);

    function addToCart() {
        for (const resourceItem of resourceSelectValue) {
            dispatch({
                type: 'ADD_TO_CART',
                data: {
                    startTime,
                    endTime,
                    total: estimate,
                    resource: resources.find(res => res.id === resourceItem.value),
                    attributes: [{
                        name: "Vehicles",
                        type: "String",
                        value: vehicles,
                    }, {
                        name: "Headcount",
                        type: "String",
                        value: headcount,
                    }]
                }
            });
        }

    }

    return (
        <Paper className={classes.paper} elevation={0}>
            <Grid container justify="center" className={classes.view}>
                <Grid item xs={12}>
                    <h2 className={classes.title}>Welcome to Alwyn Fishing!</h2>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MySnackbarContentWrapper
                        variant="info"
                        className={classes.margin}
                        message="Please note that our fishing spots are booked for the entire day.  Day visitor times are from 05:00 to 19:00."
                    />
                </Grid>
                <Grid item xs={12}>
                    <h3 className={classes.title}>Please select your arrival and departure dates</h3>
                </Grid>
            </Grid>

            <Grid container justify="center" className={classes.view}>
                <Grid container xs={12} sm={6} justify="center">
                    <DateRangePickerWrapper
                        numberOfMonths={width === 'xs' || width === 'sm' ? 1 : 2}
                        startDatePlaceholderText={"Arrival"}
                        endDatePlaceholderText={"Departure"}
                        onDatesChange={({startDate, endDate}) => {

                            const newStart = moment(startDate);
                            newStart.hour(13);
                            setStartTime(startDate);

                            if (endDate) {
                                let newEnd = moment(endDate);
                                newEnd.hour(13);
                                if (newStart.isSame(newEnd, 'day')) {
                                    newEnd.add(1, 'day').hour(0).subtract(1, 'minute');
                                }
                                setEndTime(newEnd);
                            }
                        }}
                    />
                </Grid>
            </Grid>

            {
                startTime && endTime &&
                <div className={classes.mTop2}>
                    <Grid container justify="center">
                        <Grid item xs={5} sm={4}>
                            <h3 className={classes.title}>Number of persons:</h3>
                        </Grid>

                        <Grid item xs={2} className={classes.headcountPadding}>
                            <FormControl className={classes.headcount} fullWidth required>
                                <Select
                                    id="headcount"
                                    value={headcount}
                                    onChange={(e) => {
                                        dispatch({type: 'O_CUSTOMER', data: {headcount: e.target.value}});
                                        setHeadcount(e.target.value);
                                    }}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>
                    </Grid>

                </div>


            }


            {estimate &&
            <Grid container justify="center" className={classes.mTop2}>
                <h2 className={classes.title}>{numDays} days @ R{price} per day = <strong>R {estimate}</strong></h2>
            </Grid>
            }


            <Grid container justify="center" className={classes.view}>

                <Grid item xs={12} md={6} className={classes.mTop}>
                    <BlockUi tag="div" blocking={resourceLoading}>

                        {
                            resourceSelect.length > 0 ?
                                <>
                                    <Grid item xs={12}>
                                        <h3 className={classes.title}>Please select your fishing spot(s)</h3>
                                    </Grid>
                                    <MultiSearchSelect
                                        single={false}
                                        label={<p style={{marginTop: 0}}>You can add multiple spots to this booking - view layout <a
                                            href="/#/maps" target="_blank">here</a></p>}
                                        placeholder={"Start typing to search for a spot"}
                                        value={resourceSelectValue}
                                        onChange={(val) => {
                                            setResourceSelectValue(val)
                                        }}
                                        data={resourceSelect}
                                    />

                                </> : loading && <Grid container justify="center" className={classes.view}>
                                <p>Fetching available fishing spots</p>
                            </Grid>
                        }
                    </BlockUi>
                </Grid>


                {
                    resourceSelectValue && <>
                        <Container maxWidth="sm" className={classes.section}>
                            <h2 className={classes.title}>
                                <strong>Customer Details</strong>
                            </h2>

                            <p className={classes.title}>
                                Please enter your details below
                            </p>

                            <form className={classes.form} noValidate>
                                <FormControl className={classes.margin1} fullWidth required {...emailProps}>
                                    <InputLabel shrink htmlFor="Email">
                                        Email
                                    </InputLabel>
                                    <BootstrapInput
                                        defaultValue=""
                                        id="Email"
                                        onChange={(e) => {
                                            dispatch({type: 'O_CUSTOMER', data: {email: e.target.value}});
                                            setEmail(e.target.value);
                                            // setEmailValid(validateEmail(e.target.value));
                                        }}
                                        inputProps={{
                                            onBlur: (e) => {
                                                setEmailValid(validateEmail(e.target.value))
                                            }
                                        }}
                                    />
                                </FormControl>


                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl required
                                                     className={clsx([classes.margin, width !== 'xs' ? classes.padding : ""])}
                                                     fullWidth {...firstNameProps}>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                First Name
                                            </InputLabel>
                                            <BootstrapInput defaultValue="" id="bootstrap-input"
                                                            onChange={(e) => {
                                                                setFirstName(e.target.value);
                                                                dispatch({
                                                                    type: 'O_CUSTOMER',
                                                                    data: {firstName: e.target.value}
                                                                });
                                                                // setNameValid(e.target.value !== '');
                                                            }}
                                                            inputProps={{
                                                                onBlur: (e) => {
                                                                    setFirstNameValid(e.target.value !== '');
                                                                }
                                                            }}/>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl required className={classes.margin} fullWidth {...lastNameProps}>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Last Name
                                            </InputLabel>
                                            <BootstrapInput defaultValue="" id="bootstrap-input"
                                                            onChange={(e) => {
                                                                setLastName(e.target.value);
                                                                dispatch({
                                                                    type: 'O_CUSTOMER',
                                                                    data: {lastName: e.target.value}
                                                                });
                                                                // setNameValid(e.target.value !== '');
                                                            }}
                                                            inputProps={{
                                                                onBlur: (e) => {
                                                                    setLastNameValid(e.target.value !== '');
                                                                }
                                                            }}/>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <FormControl className={classes.margin} fullWidth {...numberProps}>
                                    <InputLabel shrink htmlFor="phone">
                                        Phone Number
                                    </InputLabel>
                                    <BootstrapInput defaultValue="" id="phone"
                                                    onChange={(e) => {
                                                        setNumber(e.target.value);
                                                        dispatch({
                                                            type: 'O_CUSTOMER',
                                                            data: {phoneNumber: e.target.value}
                                                        });
                                                        // setNumberValid(e.target.value !== '');
                                                    }}
                                                    inputProps={{
                                                        onBlur: (e) => {
                                                            const valid = e.target.value !== '';
                                                            setNumberValid(valid);
                                                            if (!valid) dispatch({type: 'FORM_VALID', data: false});
                                                        }
                                                    }}/>
                                </FormControl>

                                <FormControl className={classes.margin} fullWidth {...numberProps}>
                                    <InputLabel shrink htmlFor="phone">
                                        Vehicle Registration Numbers
                                    </InputLabel>
                                    <BootstrapInput
                                        multiline
                                        rows={4}
                                        defaultValue=""
                                        id="regnumbers"

                                        onChange={(e) => {
                                            setVehicles(e.target.value);
                                            dispatch({type: 'O_CUSTOMER', data: {vehicles: e.target.value}});
                                            // setNumberValid(e.target.value !== '');
                                        }}

                                        inputProps={{
                                            onBlur: (e) => {
                                                const valid = e.target.value !== '';
                                                setNumberValid(valid);
                                                if (!valid) dispatch({type: 'FORM_VALID', data: false});
                                            }
                                        }}/>
                                </FormControl>

                                <FormControlLabel
                                    className={classes.margin}
                                    control={
                                        <GreenCheckbox
                                            checked={tnc}
                                            onChange={() => setTnc(!tnc)}
                                            value="checkedG"
                                        />
                                    }
                                    label={<p>I accept the <a href="/#/terms-and-conditions" target="_blank">terms and
                                        conditions</a></p>}
                                />

                                <Grid container justify="center" className={classes.topSpacing}>


                                    <Button variant="contained"
                                            disabled={!formValid}
                                            color="primary"
                                            onClick={() => {
                                                addToCart();
                                                history.push('/checkout');
                                            }}
                                            className={classes.button}>Continue to checkout</Button>
                                </Grid>
                            </form>
                        </Container>
                    </>
                }


            </Grid>
        </Paper>
    )
        ;
}

export default withRouter(withWidth()(OptionsView));
