import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    title: {
        marginTop: theme.spacing(4),
        textAlign: "center"
    }
}));

function TermsAndConditions() {
    const classes = useStyles();

    return (
        <Paper className={classes.paper} elevation={0}>
            <Grid container justify="center" alignItems="center" className={classes.view}>
                <Grid item xs={12} md={6}>
                    <h3 className={classes.title}>Terms and Conditions</h3>
                </Grid>
            </Grid>
            <Grid container justify="center" className={classes.view}>
                <Grid item xs={12} md={6}>
                    <ol>
                        <li>
                            Enter at own risk. Alwyn does not accept any accountability or liability in any death,
                            injury or
                            illness sustained or suffered by any person.
                            You hereby indemnify the owner of Alwyn from and against any damages, theft or losses.
                        </li>

                        <li> We only have 15 exclusive fishing spots available. All bookings are made on a
                            first-come-first-serve basis and no exceptions will be made.
                        </li>
                        <li> We use PayFast as a payment processing service. They enable easy, secure and instant
                            transfer
                            payments.
                            Payment may be made via instant EFT, credit and cheque cards, MasterCard, Visa, debit cards
                            and
                            Zapper.
                        </li>
                        <li> Alwyn only confirms a booking on receipt of full payment.</li>
                        <li> It remains the client’s responsibility to ensure that the proof of payment is received by
                            the
                            booking office before entering Alwyn.
                        </li>
                        <li> Visitors are to have their booking confirmation with them upon entrance to Alwyn with the
                            motor
                            vehicle registered when booking was made. This is to insure the safety of all our client’s
                            entering
                            the premises.
                        </li>
                        <li>Braai facilities available.  (only use charcoal - no open fires allowed). </li>
                        <li> Day visitors welcome between 05:00 and 19:00.</li>
                        <li> Catch and release only.</li>
                        <li> Maximum 5 people per fishing spot.</li>
                        <li> Toilet facilities and water available.</li>
                        <li> Please stay on appointed gravel road.</li>
                        <li> Only fishing and canoeing allowed.</li>
                        <li> No swimming, diving, bathing and other water activities will be permitted.</li>
                        <li> No showers available.</li>
                        <li> No load music.</li>
                        <li> No pets allowed.</li>
                        <li> Please leave fishing spot clean.</li>
                        <li> No Refunds.</li>
                        <li> All tariffs are subject to change without notification.</li>
                        <li> Please don’t kill the cattle.</li>
                        <li> Enjoy yourself.</li>
                    </ol>
                </Grid>

            </Grid>
        </Paper>
    )
        ;
}

export default (TermsAndConditions);
