import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ClearCartIcon from "@material-ui/icons/RemoveShoppingCartTwoTone";
import {useStateValue} from "../state/StateProvider";
import moment from "moment";
import clsx from "clsx";
import RegularButton from "./CustomButtons/Button";
import {withRouter} from "react-router-dom";


const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: '700',
    },
    title: {
        marginTop: theme.spacing(2),
    },
    basket: {
        maxHeight: '70%',
        padding: theme.spacing(2),
        left: theme.spacing(2),
        width: '100%',
        zIndex: 1300,
    },
    event: {
        marginRight: '24px',
    },
    font: {
        fontWeight: 300,
        color: "#555",
    },
}));

function BasketView({currencySymbol, basketTotal, basket, onClose, history}) {
    const classes = useStyles();
    const [_, dispatch] = useStateValue();


    return (
        <Paper className={classes.basket}>
            <Typography variant="h6" gutterBottom className={classes.font}>
                Booking summary
            </Typography>
            <List disablePadding>
                {basket.map(event => {
                    const stT = moment(event.startTime).format("dd DD MMM");
                    const endT = moment(event.endTime).format("dd DD MMM");
                    const secondary = moment(event.startTime).isValid() ? `${stT} - ${endT}` : null;
                    return (
                        <ListItem className={classes.listItem} key={event.event}>
                            <ListItemText
                                primary={event.event}
                                secondary={secondary}
                                className={clsx([classes.event, classes.font])}
                            />
                            <Typography variant="body2">R {event.total}</Typography>
                        </ListItem>
                    )
                })}
                <ListItem className={classes.listItem}>
                    <ListItemText primary="Total"/>
                    <Typography variant="subtitle1" className={classes.total}>
                        {currencySymbol} {basketTotal}
                    </Typography>
                </ListItem>
            </List>
            <Grid container justify="center">
                <Grid item xs={4} className={classes.buttonColumn}>
                    {basket.length > 0 ?
                        <Tooltip title="Clear cart" placement="bottom">
                            <IconButton
                                color="primary"
                                className={classes.clearCartIcon}
                                onClick={() => {
                                    dispatch({type: 'CLEAR_CART'});
                                    // setSnack(<Snack onClose={() => setSnack(null)}
                                    //                 open={!!snack}
                                    //                 message={"Your cart has been cleared :)"}
                                    //                 variant={"success"}/>);
                                }}
                            >
                                <ClearCartIcon/>
                            </IconButton>
                        </Tooltip> : null}
                </Grid>
                <Grid item xs={8} className={classes.buttonColumn}>
                    <RegularButton
                        color="primary"
                        variant="outlined"
                        className={clsx([classes.button, classes.bookingButton])}
                        aria-label="add to shopping cart"
                        onClick={() => {
                            history.push('/customer-details');
                        }}>Checkout</RegularButton>
                </Grid>
            </Grid>


            {/*<Grid container spacing={2}>*/}

            {/*    <Grid item container direction="column" xs={12} sm={6}>*/}
            {/*        <Typography variant="h6" gutterBottom className={classes.title}>*/}
            {/*            Payment details*/}
            {/*        </Typography>*/}
            {/*        <Grid container>*/}
            {/*            {payments.map(payment => (*/}
            {/*                <React.Fragment key={payment.name}>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <Typography gutterBottom>{payment.name}</Typography>*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={6}>*/}
            {/*                        <Typography gutterBottom>{payment.detail}</Typography>*/}
            {/*                    </Grid>*/}
            {/*                </React.Fragment>*/}
            {/*            ))}*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
        </Paper>
    );
}


export default withRouter(BasketView);
