import React from "react";
import {AmplifyTheme} from "aws-amplify-react";
import awsconfig from "./aws-exports";
import App from "./App";
import Amplify, {Auth} from "aws-amplify";
import reducer, {initialState} from "./state/reducer";
import {StateProvider} from "./state/StateProvider";
import ApolloClient from "apollo-boost";
import {createHttpLink} from "apollo-link-http";
import {setContext} from 'apollo-link-context';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloProvider} from "react-apollo";
import {ApolloProvider as ApolloHooksProvider} from "@apollo/react-hooks";
import {ApolloLink} from 'apollo-link';
import AWSAppSyncClient from 'aws-appsync';
import ErrorBoundary from "./ErrorBoundary";

Amplify.configure(awsconfig);
const theme = {
    ...AmplifyTheme,
    navBar: {
        ...AmplifyTheme.navBar,
        backgroundColor: "#ffc0cb"
    },
    button: {
        ...AmplifyTheme.button,
        backgroundColor: "var(--amazonOrange)"
    },
    sectionBody: {
        ...AmplifyTheme.sectionBody,
        padding: "5px"
    },
    sectionHeader: {
        ...AmplifyTheme.sectionHeader,
        backgroundColor: "var(--squidInk)"
    }
};
const federated = {
    facebook_app_id: '275872790015594',
};

const httpLink = createHttpLink({
    uri: `${awsconfig.aws_appsync_graphqlEndpoint}/graphql`,
});

const authLink = setContext(async (operation, {headers}) => {
    const token = (await Auth.currentSession()).idToken.jwtToken;
    return {
        ...headers,
        authorization: token || null
    }
});


const client = new ApolloClient({
    uri: awsconfig.aws_appsync_graphqlEndpoint,
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache(),
    request: async (operation) => {
        const token = (await Auth.currentSession()).idToken.jwtToken;
        operation.setContext({
            headers: {
                authorization: token
            }
        });
    }
});

const asClient = new AWSAppSyncClient({
    url: awsconfig.aws_appsync_graphqlEndpoint,
    region: awsconfig.aws_appsync_region,
    auth: {
        type: awsconfig.aws_appsync_authenticationType,
        apiKey: awsconfig.aws_appsync_apiKey,
        jwtToken: async () => (await Auth.currentSession()).idToken.jwtToken, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
    }
})

class AuthedApp extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <ErrorBoundary>
                <ApolloProvider client={asClient}>
                    <ApolloHooksProvider client={client}>
                        <StateProvider initialState={initialState} reducer={reducer}>
                            <App/>
                        </StateProvider>
                    </ApolloHooksProvider>
                </ApolloProvider>
            </ErrorBoundary>
        );
    }
}

export default AuthedApp;
