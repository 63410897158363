import React, {Fragment, useEffect, useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PayFastView from "./PayfastView";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {useStateValue} from "../state/StateProvider";
import Snack from "../components/Snack";
import payfastCheckout from '../assets/img/payfast-chackout.png';
import BasketContent from "../components/BasketContent";
import RegularButton from "../components/CustomButtons/Button";
import {withRouter} from "react-router-dom";
import EFTView from "../views/EFTView";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    button: {
        width: '100%',
        marginTop: theme.spacing(3)
    },
    fab: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    title: {
        textAlign: "center",
        color: '#555555',
        fontWeight: '400',
        margin: '0 0 8px 0',
    },
    title1: {
        textAlign: 'center',
        color: '#555555',
        fontWeight: '300',
        margin: theme.spacing(2),
    },
    basket: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(8),
    },
    optionCard: {
        margin: theme.spacing(2),
    }

}));


function PaymentOption({location}) {
    const classes = useStyles();
    const [{snack, basketTotal, basket, rawBasket}, dispatch] = useStateValue();
    const [type, setType] = React.useState(null);
    const [hover, setHover] = useState({});

    useEffect(() => {
        if (basket.length === 0) {
            window.location.href = '/';
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const hasCoupon = !!rawBasket.find(item => item.type === "coupon");

    const view = () => {
        switch (type) {
            case "CC":
                return <PayFastView/>;
            case "EFT":
                return <EFTView/>;
            default:
                return <Fragment>
                    <h2 className={classes.title1}>
                        Payment Options</h2>


                    <Typography component="p" variant="p" align="center" className={classes.title1}>
                        Please select your payment option below
                    </Typography>


                    <Grid container justify="center">

                        {
                            !hasCoupon && <Grid item xs={12} sm={4}>
                                <Card className={classes.optionCard}
                                      elevation={hover['EFT'] || 1}
                                      onMouseOver={() => setHover({EFT: 4})}
                                      onMouseOut={() => setHover({EFT: 1})}
                                      style={{cursor: 'pointer'}}
                                      onClick={() => setType("EFT")}
                                >
                                    <CardContent>
                                        <Grid container justify="center">
                                            <Grid item xs={12}>
                                                <h2 className={classes.title}>EFT</h2>
                                            </Grid>


                                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                                <RegularButton onClick={() => setType("EFT")}
                                                               style={{
                                                                   backgroundColor: '#262262',
                                                                   textAlign: "center"
                                                               }}>
                                                    Click here to book!</RegularButton>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <p className={classes.title1}>Transfer funds from your bank account and
                                                    email us the proof of
                                                    payment.</p>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        }


                        <Grid item xs={12} sm={4}>
                            <Card className={classes.optionCard}
                                  elevation={hover['CC'] || 1}
                                  onMouseOver={() => setHover({CC: 4})}
                                  onMouseOut={() => setHover({CC: 1})}
                                  style={{cursor: 'pointer'}}
                                  onClick={() => setType("CC")}
                            >
                                <CardContent>
                                    <Grid container justify="center">
                                        <Grid item xs={12}>
                                            <h2 className={classes.title}>Credit Card</h2>
                                        </Grid>

                                        <Grid item xs={12} alignItems="center" style={{textAlign: 'center'}}>
                                            <img alt='logo' style={{width: '200px'}}
                                                 src={payfastCheckout}/>
                                        </Grid>

                                        <Grid item xs={12} style={{textAlign: 'center'}}>
                                            <RegularButton onClick={() => setType("CC")}
                                                           style={{backgroundColor: '#262262', textAlign: "center"}}>Click
                                                here to
                                                pay!</RegularButton>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <p className={classes.title1}>You will be redirected to PayGate to
                                                complete
                                                the
                                                payment.</p>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

                </Fragment>
        }
    };

    const snackView = snack ? (
        <Snack onClose={() => dispatch({type: 'HIDE_SNACKS'})}
               open={true}
               message={snack.message}
               variant={snack.variant}/>
    ) : null;

    return (
        <Container maxWidth="md">
            <CssBaseline/>
            {snackView}
            {view()}


            <div className={classes.basket}>
                <BasketContent
                    currencySymbol={"R"}
                    basketTotal={basketTotal}
                    basket={basket}
                    buttons={false}
                    onClose={() => {
                    }}/>
            </div>
        </Container>
    );
}

export default withRouter(PaymentOption);
