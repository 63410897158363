import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import BootstrapInput from "./BootstrapInput";
import Divider from "@material-ui/core/Divider";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles(theme => ({
    marginTop: {
        marginTop: theme.spacing(3),
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '0',
        fontSize: '12px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1',
        letterSpacing: '0.00938em',
        marginBottom: "12px"
    },
}));


export default function ChildDetails() {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = React.useState(new Date());


    return (
        <React.Fragment>
            <Divider className={classes.marginTop}/>
            <Grid container justify="center">
                <h3 className={classes.marginTop}>Add Child #1</h3>
            </Grid>
            <FormControl fullWidth >
                <InputLabel shrink htmlFor="cname">
                    Child Name
                </InputLabel>
                <BootstrapInput defaultValue="" id="cname"/>
            </FormControl>

            <FormControl fullWidth className={classes.marginTop}>
                <InputLabel shrink htmlFor="sname">
                    School Name
                </InputLabel>
                <BootstrapInput defaultValue="" id="sname"/>
            </FormControl>

            <FormControl fullWidth className={classes.marginTop}>
                <label className={classes.label}>Birthday </label>
                <Datetime
                    renderInput={(props, openCalendar, closeCalendar) => {
                        function clear() {
                            props.onChange({target: {value: ''}});
                        }

                        return (
                            <BootstrapInput {...props} />
                        );
                    }}
                    id="bday"
                    input={true}
                    onChange={event => setSelectedDate(event)}
                    defaultValue={selectedDate}
                    dateFormat={true}
                    timeFormat={false}
                    inputProps={{placeholder: "Birthday"}}
                />
            </FormControl>


        </React.Fragment>
    )
}
