import React, {Fragment, useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import {initTransaction} from "../actions/appActions";
import {useStateValue} from "../state/StateProvider";
import {withWidth} from "@material-ui/core";
import {useTheme} from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {apihost} from "../aws-exports";


const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    button: {
        width: '100%',
        marginTop: theme.spacing(3)
    },
    fab: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    title1: {
        textAlign: 'center',
        color: '#555555',
        fontWeight: '300',
        margin: theme.spacing(2),
    },
}));


function PayFastView({width}) {
    const classes = useStyles();
    const theme = useTheme();
    const mobile = width === 'xs';

    const [{company, location, rawBasket, basket, customer, payment, basketTotal, user}, dispatch] = useStateValue();

    useEffect(() => {
        initTransaction({
            company: company.id,
            dispatch,
            customer,
            location: location.BranchId,
            basketTotal,
            rawBasket,
            basket,
            paymentType: "PayFast",
            user
        })
    }, []);


    useEffect(() => {
        if (payment.reference) {
            let form = document.getElementById('payfastform');
            form.submit();
        }
    }, [payment]);


    let cust;
    if (user) {
        cust = user.profile;
    } else {
        cust = customer;
    }

    const payfastConfig = company.paymentMethods.items.find(pm => pm.type.id === 'PayFast');

    const mid = payfastConfig.settings.find(st => st.key === 'merchantId').value;
    const mKey = payfastConfig.settings.find(st => st.key === 'merchantKey').value;
    const endpoint = payfastConfig.settings.find(st => st.key === 'endpoint').value;
    const sendNotification = payfastConfig.settings.find(st => st.key === 'sendNotification').value;
    const notificationEmail = payfastConfig.settings.find(st => st.key === 'notificationEmail').value;

    const returnUrl = `${window.location.href.split("#")[0]}#/completed?refresh=true`;
    const cancelUrl = `${window.location.href.split("#")[0]}#/cancelled?refresh=true`;

    return (
        <Container maxWidth="xs">
            <CssBaseline/>

            <form id="payfastform" action={endpoint} method="POST">
                <input type="hidden" name="merchant_id" value={mid}/>
                <input type="hidden" name="merchant_key" value={mKey}/>
                <input type="hidden" name="return_url" value={returnUrl}/>
                <input type="hidden" name="cancel_url" value={cancelUrl}/>
                <input type="hidden" name="notify_url" value={`https://${apihost}/notify/payfast`}/>
                <input type="hidden" name="custom_str1" value={company.id}/>

                <input type="hidden" name="name_first" value={cust.firstName}/>
                <input type="hidden" name="name_last" value={cust.lastName}/>
                <input type="hidden" name="email_address" value={cust.email}/>
                {/*<input type="hidden" name="cell_number" value={customer.phoneNumber}/>*/}

                <input type="hidden" name="m_payment_id" value={payment.id}/>
                <input type="hidden" name="amount" value={basketTotal}/>
                <input type="hidden" name="item_name" value={"Alwyn Fishing"}/>
                {sendNotification &&
                <Fragment>
                    <input type="hidden" name="email_confirmation" value="1"/>
                    <input type="hidden" name="confirmation_address" value={notificationEmail}/>
                </Fragment>
                }
            </form>


            <h2 className={classes.title1}>Credit Card Payment</h2>

            <p className={classes.title1}>Please wait while we initialise your booking...</p>
            <p className={classes.title1}>You will be redirected to our payment partner shortly.</p>

            <Grid container justify="center">
                <CircularProgress disableShrink/>
            </Grid>

        </Container>
    );
}

export default withWidth()(PayFastView);
