import moment from "moment";

export const initialState = {
    basket: [],  // items that are displayed to the customer
    rawBasket: [],  // actual broken down items  that are sent to the API (e.g. bundles are displayed as single line but actually sent as multiple lines
    locations: [],
    events: [],
    resources: [],
    locationEvents: {},
    location: null,
    currency: 'ZAR',
    type: null,
    term: null,
    customer: null,
    validator: null,
    snapscan: {},
    payment: {},
    formValid: false,
    basketTotal: 0,
    option: null,
    validEventDays: {},
    user: null,
    userBookings: null,
    userVouchers: [],
    loginLoading: true,
    error: null,
    refreshVouchers: true, // initial voucher load
};

const reducer = (state, action) => {
        switch (action.type) {
            case 'REMOVE_FROM_CART': {
                const {data: {id, startTime}} = action;

                state.basket = state.basket.filter(ev => ev.startTime !== startTime);
                state.rawBasket = state.rawBasket.filter(ev => ev.item.event !== id);

                let btotal = state.rawBasket.reduce((total, item) => {
                    return total + parseFloat(item.total)
                }, 0);

                return {
                    ...state,
                    basketTotal: btotal,
                };
            }
            case 'ADD_TO_CART': {

                const {data: {startTime, endTime, total, resource, attributes}} = action;

                startTime.hour(5);
                endTime.hour(19);

                const dayDiff = moment(endTime).diff(startTime, 'days');
                const days = dayDiff + 1;
                state.basket.push({
                    qty: days,
                    event: resource.name,
                    startTime: startTime,
                    endTime: endTime,
                    total: total,
                });

                state.rawBasket.push({
                    type: 'reservation',
                    item: {
                        resource: resource.id,
                        startTime: startTime.toISOString(),
                        endTime: endTime.toISOString()
                    },
                    total: total,
                    attributes
                });


                let btotal = state.rawBasket.reduce((total, item) => {
                    return total + parseFloat(item.total)
                }, 0);

                return {
                    ...state,
                    basketTotal: btotal,
                };
            }


            case 'OPTION': {
                return {
                    ...state,
                    option: action.data,
                }
            }
            case 'GET_LOCATIONS_COMPLETED': {
                return {
                    ...state,
                    locations: action.data,
                    location: action.data[0],
                    loading: false,
                };
            }
            case "FETCH_RESOURCES":
            case "GET_LOCATIONS":
            case "GET_EVENTS":
            case 'GET_COMPANY': {
                return {
                    ...state,
                    loading: true,
                };
            }
            case 'FETCH_RESOURCES_COMPLETED': {
                return {
                    ...state,
                    resources: action.data,
                    loading: false,
                };
            }
            case 'GET_COMPANY_COMPLETED': {
                return {
                    ...state,
                    company: action.data,
                    loading: false,
                };
            }

            case 'FORM_VALID': {
                return {
                    ...state,
                    formValid: action.data,
                }
            }

            case 'O_CUSTOMER': {

                return {
                    ...state,
                    customer: {
                        ...state.customer,
                        ...action.data
                    },
                };
            }
            case 'LOC_SELECTED': {
                return {
                    ...state,
                    location: action.data
                };
            }
            case 'CLEAR_CART': {
                return {
                    ...state,
                    basketTotal: 0,
                    basket: [],
                    rawBasket: [],
                };
            }

            case 'VALIDATOR': {
                return {
                    ...state,
                    validator: action.data,
                }
            }

            case 'SNAPSCAN_INIT_COMPLETE': {

                const snapsc = {
                    ...action.data,
                    ccid: action.data.payments[0].id,
                    snapscanReady: true,
                    startPolling: true,
                }

                return {
                    ...state,
                    snapscan: snapsc,
                };
            }
            case 'ZAPPER_INIT_COMPLETE': {

                const snapsc = {
                    ...action.data,
                    ccid: action.data.payments[0].id,
                    ready: true,
                    startPolling: true,
                }

                return {
                    ...state,
                    payment: snapsc,
                };
            }
            case 'EFT_INIT_COMPLETE': {

                const pmt = {
                    ...action.data,
                    reference: action.data.payments[0].ref,
                };

                return {
                    ...state,
                    payment: pmt,
                };
            }

            case 'USER_LOGIN_STARTED':
            case 'SIGNING_IN': {
                return {
                    ...state,
                    loginLoading: true,
                };
            }

            case 'CLEAR_LOGIN_ERROR': {
                return {
                    ...state,
                    error: null
                }
            }
            case 'USER_LOGIN_COMPLETED': {

                const {user, error, fedpayload} = action;
                state.loginLoading = false;
                if (error) {
                    return {
                        ...state,
                        error: error.message,
                    };
                } else if (user) {

                    const cp = user.profile && user.profile.coupons ? user.profile.coupons.items
                        .filter(cp => cp.priceOption)
                        .find(cp => {
                            const active = cp.status === 'ACTIVE';
                            return active && (cp.priceOption.name === 'One Month Pass' || cp.priceOption.name === 'Two Month Pass');
                        }) : null;

                    return {
                        ...state,
                        error: null,
                        user: {
                            ...user,
                            coupons: cp,
                        },

                    };
                } else if (fedpayload) {
                    return {
                        ...state,
                    }
                } else {
                    return {
                        ...state,
                    }
                }
            }

            case 'PAYGATE_INIT_COMPLETE': {

                const pmt = {
                    ...action.data.extra,
                    reference: action.data.payments[0].ref,
                    requestId: action.data.extra.PAY_REQUEST_ID,
                    checksum: action.data.extra.CHECKSUM,
                };

                return {
                    ...state,
                    payment: pmt,
                };
            }
            case 'PAYFAST_INIT_COMPLETE': {

                if (action.data.result && action.data.result === 'ERROR') {
                    return {
                        ...state,
                        snack: {
                            variant: "error",
                            message: `Could not initiate booking, please contact support. (${action.data.reason})`
                        },
                    };
                } else {
                    const pmt = {
                        ...action.data.extra,
                        reference: action.data.payments[0].ref,
                        id: action.data.payments[0].id,
                    };

                    return {
                        ...state,
                        payment: pmt,
                    };
                }


            }

            case 'CASH_INIT_COMPLETE': {

                if (action.data.result && action.data.result === 'ERROR') {
                    return {
                        ...state,
                        snack: {
                            variant: "error",
                            message: `Could not initiate booking, please contact support. (${action.data.reason})`
                        },
                    };
                } else {
                    const pmt = {
                        ...action.data.extra,
                        reference: action.data.payments[0].ref,
                        id: action.data.payments[0].id,
                    };

                    return {
                        ...state,
                        payment: pmt,
                    };
                }


            }

            case 'TOGGLE_EVENT': {

                const checked = state.basket.filter(b => b.eventId === action.data).length === 1;

                if (checked) {
                    // remove from basket
                    state.basket = state.basket.filter(b => b.eventId !== action.data.eventId);
                } else {
                    // add to basket
                    state.basket.push(action.data);
                }
                return {
                    ...state,
                };
            }
            case 'HIDE_SNACKS': {
                return {
                    ...state,
                    snack: null,
                }
            }

            case 'SUB': {
                return {
                    ...state,
                    sub: action.data,
                }
            }
            case 'FETCH_EVENTS_COMPLETED': {

                const uniqueTypes = new Set();
                const tmp = {};

                action.data
                    .forEach(event => {
                        uniqueTypes.add(event.typeName);  // FIXME
                        // if (event.Term !== 'Individual')
                        //     tmp[event.Term] = event.Term;
                    });


                const typeTerms = {};

                action.data
                    .filter(el => el.typeName === state.type)
                    .forEach(el => {
                        if (!typeTerms[el.Term]) typeTerms[el.Term] = 0;
                        typeTerms[el.Term] += 1;
                    });

                const uniqueTermsType = new Set();
                Object.keys(typeTerms).map(el => {
                    uniqueTermsType.add({
                        label: `${el} - (${typeTerms[el]} sessions)`,
                        value: `${el}`
                    })
                });


                /*
                 * Trial Event Options
                 */
                const eventOptions = {};
                action.data.forEach(event => {
                    const startDate = new Date(event.startTime);
                    const endDate = new Date(event.endTime);
                    const startTime = `${startDate.getHours()}:${(startDate.getMinutes() < 10 ? '0' : '') + startDate.getMinutes()}`;
                    const endTime = `${endDate.getHours()}:${(endDate.getMinutes() < 10 ? '0' : '') + endDate.getMinutes()}`;
                    const formattedDateString = `${new Intl.DateTimeFormat('en-GB', {
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric',

                    }).format(new Date(event.startTime))} ${startTime} - ${endTime}`;

                    if (!eventOptions[event.typeName]) eventOptions[event.typeName] = [];
                    eventOptions[event.typeName].push({
                        label: formattedDateString,
                        value: formattedDateString,
                        event: event
                    });
                });


                return {
                    ...state,
                    events: action.data,
                    uniqueTypes: uniqueTypes,
                    uniqueTerms: uniqueTermsType,
                    singleEvents: eventOptions,
                    loading: false,
                };
            }

            case 'ON_UPDATE_EVENT': {

                if (action.error) {
                    return {
                        ...state,
                        snack: {
                            text: action.error,
                            color: 'warning',
                        },
                    }
                }

                // replace the item with the new one
                const booking = action.booking;
                const voucher = action.voucher;

                if (booking) {
                    state.events = state.events.map(old => {
                        if (booking.Event.id === old.id) {
                            return {
                                ...old,
                                headcount: booking.Event.headcount,
                            }
                        } else return old;
                    });
                }

                if (voucher) {
                    // a voucher was created with a cancellation
                    state.user.profile.vouchers.items.push(voucher);
                }

                return {
                    ...state,
                }
            }
            case 'RESET_USER_DATA': {

                return {
                    ...initialState,
                    loginLoading: false,
                }
            }
            case 'CLEAR_SNACK': {
                return {
                    ...state,
                    snack: null,
                }
            }
            default:
                return state;
        }
    }
;

export default reducer;
