import React, {useEffect} from 'react';
import './App.css';
import {HashRouter as Router, Route} from "react-router-dom";
import ResultPage from "./ResultPage";
import BlockUi from 'react-block-ui';
import AppContainer from "./views/AppContainer";
import {getCompany, getLocations} from "./actions/appActions";
import {useStateValue} from "./state/StateProvider";
import OptionsView from "./views/OptionsView";
import PaymentOption from "./steps/PaymentOptions";
import CustomerDetails from "./components/CustomerDetails";
import TermsAndConditions from "./views/TermsAndConditions";
import Maps from "./views/Maps";


let regex = /[?&]([^=#]+)=([^&#]*)/g,
    url = window.location.href,
    params = {},
    match;
while (match = regex.exec(url)) {
    params[match[1]] = match[2];
}


function App() {
    const [{company,}, dispatch] = useStateValue();

    useEffect(() => {
        getCompany(dispatch);
        getLocations(dispatch);

        if (window.location.href.includes('refresh=true')) {
            setTimeout(() => {
                window.location.reload();
            }, 1000);
            window.location.href = window.location.href.split('refresh=true')[0];

        }

        return () => {
            console.log('App cleanup');
        }
    }, []);


    return (
        <BlockUi tag="div" blocking={!company}>
            <Router>
                <Route exact path="/" render={({history}) => (
                    <AppContainer step={5}>
                        <OptionsView/>
                    </AppContainer>
                )}/>
                <Route exact path="/checkout" render={(props) =>
                    <AppContainer step={2}>
                        <PaymentOption {...props}/>
                    </AppContainer>}/>

                <Route exact path="/customer-details" render={(props) =>
                    <AppContainer step={2}>
                        <CustomerDetails {...props}/>
                    </AppContainer>}/>

                <Route exact path="/terms-and-conditions" render={(props) =>
                    <AppContainer step={2}>
                        <TermsAndConditions {...props}/>
                    </AppContainer>}/>


                <Route exact path="/result" render={(props) =>
                    <AppContainer step={5}>
                        <ResultPage {...props} params/>
                    </AppContainer>}/>

                <Route exact path="/maps" render={(props) =>
                    <AppContainer step={5}>
                        <Maps {...props} params/>
                    </AppContainer>}/>
            </Router>
        </BlockUi>
    );

}

export default App;

