import React, {useEffect, useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import BootstrapInput from "../components/BootstrapInput";
import ChildDetails from "../components/ChildDetails";
import {green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import ssLogo from '../assets/img/ssLogo.png';
import zapLogo from '../assets/img/zapper.png';
import Fab from "@material-ui/core/Fab";
import {shadows} from '@material-ui/system';
import {initSnapScan, initTransaction} from "../actions/appActions";
import {useStateValue} from "../state/StateProvider";
import {useMediaQuery, withWidth} from "@material-ui/core";
import {useTheme} from "@material-ui/styles";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snack from "../components/Snack";
import clsx from "clsx";


const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    button: {
        width: '100%',
        marginTop: theme.spacing(3)
    },
    fab: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    title1: {
        textAlign: 'center',
        color: '#555555',
        fontWeight: '300',
        margin: theme.spacing(2),
    },
    mTop: {
        marginTop: theme.spacing(4),
    },
}));


function EFTView({width}) {
    const classes = useStyles();
    const [{company, location, rawBasket, basket, customer, payment, basketTotal}, dispatch] = useStateValue();
    const [localSnack, setLocalSnack] = React.useState(null);
    useEffect(() => {
        initTransaction({
            company: company.id,
            dispatch,
            customer,
            location: location.BranchId,
            basketTotal,
            rawBasket,
            basket,
            paymentType: "EFT"
        })
    }, []);

    useEffect(() => {
        if (payment.reference) {
            setLocalSnack(<Snack onClose={() => setLocalSnack(false)}
                                 open={true}
                                 message={"Your booking has been provisionally approved.  Please make payment to confirm your booking."}
                                 variant={"success"}/>);
        }
    }, [payment.reference]);

    return (
        <Container maxWidth="xs">
            <CssBaseline/>
            {localSnack != false && localSnack}
            {/*desktop view*/}
            <Hidden xsDown>
                <h3 className={clsx([classes.title1, classes.mTop])}>EFT Payment</h3>

                <h3 className={clsx([classes.title1, classes.mTop])}>
                    {payment.reference ? 'Your booking has been provisionally approved pending payment' : 'Please wait while we initialise your booking...'}
                </h3>

                <Grid container justify="center">

                    <h3 className={clsx([classes.title1, classes.mTop])}> Please send proof of payment to <a
                        href="mailto:pop@canvasclub.co.za">info@alwynfishing.co.za</a>
                    </h3>

                    <table>
                        <tr>
                            <td><strong>Bank</strong></td>
                            <td>ABSA</td>
                        </tr>
                        <tr>
                            <td><strong>Reference</strong></td>
                            <td>{payment.reference ? payment.reference : <CircularProgress/>}</td>
                        </tr>
                        <tr>
                            <td><strong>Beneficiary</strong></td>
                            <td>FA Viljoen</td>
                        </tr>
                        <tr>
                            <td><strong>Account Type</strong></td>
                            <td>Cheque/Current Account</td>
                        </tr>
                        <tr>
                            <td><strong>Account Number</strong></td>
                            <td>2310174613</td>
                        </tr>
                        <tr>
                            <td><strong>Branch Code</strong></td>
                            <td>632005</td>
                        </tr>
                    </table>

                </Grid>
            </Hidden>

            {/*mobile view*/}
            <Hidden smUp>
                <Typography component="h6" variant="h6" align="center" style={{marginTop: '24px'}}>
                    EFT Payment
                </Typography>

                <Typography component="p" variant="p" align="center">
                    {payment.reference ? 'Your booking has been provisionally approved pending payment' : 'Please wait while we initialise your booking...'}
                </Typography>

                <Grid container justify="center">

                    <h3 className={clsx([classes.title1, classes.mTop])}> Please send proof of payment to <a
                        href="mailto:pop@canvasclub.co.za">info@alwynfishing.co.za</a>
                    </h3>

                    <table>
                        <tr>
                            <td><strong>Bank</strong></td>
                            <td>ABSA</td>
                        </tr>
                        <tr>
                            <td><strong>Reference</strong></td>
                            <td>{payment.reference ? payment.reference : <CircularProgress/>}</td>
                        </tr>
                        <tr>
                            <td><strong>Beneficiary</strong></td>
                            <td>FA Viljoen</td>
                        </tr>
                        <tr>
                            <td><strong>Account Type</strong></td>
                            <td>Cheque/Current Account</td>
                        </tr>
                        <tr>
                            <td><strong>Account Number</strong></td>
                            <td>2310174613</td>
                        </tr>
                        <tr>
                            <td><strong>Branch Code</strong></td>
                            <td>632005</td>
                        </tr>
                    </table>

                </Grid>
            </Hidden>

        </Container>
    );
}

export default withWidth()(EFTView);
