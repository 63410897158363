import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {withWidth} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {withRouter} from "react-router-dom";
import OptionsView from "./views/OptionsView";

const useStyles = makeStyles(() => ({


    title: {
        display: "inline-block",
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        color: '#555555',
        fontWeight: '300',
        textAlign: "center",
    },
    description: {
        padding: '16px',
        fontWeight: 300,
        color: "#6d6d6d",
        textAlign: "center !important"
    },
    name: {
        textAlign: 'center',
    },

}));


function ResultPage({width, params, history}) {
    const classes = useStyles();

    let completed = params.result === 1;
    const cancelled = params.result === 2;

    const completedView = <Paper className={classes.paper} elevation={0}>
        <Grid container>
            <Grid item xs={12} className={classes.name}>
                <h1 className={classes.title}>Thank you!</h1>
            </Grid>
            <Grid item xs={12} className={classes.name}>
                <h3 className={classes.title}>Your booking has been confirmed, please check
                    your inbox for the confirmation details.
                </h3>
            </Grid>
            <Grid item xs={12} className={classes.name}>
                <h3 className={classes.title}>See you soon!</h3>
            </Grid>

            <OptionsView welcome onSelect={(option) => {
                switch (option) {
                    case 'indiv':
                        history.push('sessions');
                        break;
                    case 'oneMonth':
                    case 'twoMonth':
                        history.push('purchase-pass');
                        break;
                    default:
                        break;
                }
            }}/>
        </Grid>
    </Paper>;

    const cancelledView = <Paper className={classes.paper} elevation={0}>
        <Grid container>
            <Grid item xs={12} className={classes.name}>
                <h1 className={classes.title}>Thank you!</h1>
            </Grid>
            <Grid item xs={12} className={classes.name}>
                <h3 className={classes.title}>Your booking has been cancelled and your card was not charged.</h3>
            </Grid>

            <OptionsView welcome onSelect={(option) => {
                switch (option) {
                    case 'indiv':
                        history.push('sessions');
                        break;
                    case 'oneMonth':
                    case 'twoMonth':
                        history.push('purchase-pass');
                        break;
                    default:
                        break;
                }
            }}/>
        </Grid>
    </Paper>;


    if(!completed && !cancelled)
        completed = true;

    return (
        <Fragment>
            {completed && completedView}
            {cancelled && cancelledView}
        </Fragment>
    );
}


export default withRouter(withWidth()(ResultPage));
